<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="4">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr
                            class="form-control"
                            value=""
                            :config="{
                              dateFormat: 'd/m/Y',
                              mode: 'range',
                            }"
                            placeholder="Select Date"
                            style="background-color: transparent"
                            @input="getdata($event)"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Lead name</label>
                          <v-select
                            v-model="lead"
                            label="fullname"
                            placeholder="None"
                            :options="$store.state.master.userLead"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group v-if="User == 'admin'">
                          <label>Type</label>
                          <v-select
                            @input="getEmployee($event)"
                            v-model="branchname"
                            placeholder="None"
                            label="name"
                            :options="branchOptions"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" v-if="branchname && branchname.name !== 'All'">
                        <b-form-group>
                          <label
                            >Assign to
                            {{
                              branchname.name == "Admin" ? "Employee" : branchname.name
                            }}</label
                          >
                          <v-select
                            v-model="assignto"
                            label="fullname"
                            placeholder="None"
                            :options="$store.getters['master/getUsers'](branchname.id)"
                          >
                            <template
                              #option="{ name, profile_image, username, surname }"
                            >
                              <b-avatar :src="getprofileImage(profile_image)" />
                              <span class="font-weight-bolder">
                                {{ name }} {{ surname }}</span
                              ><span>({{ username }})</span>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Lead Stage</label>
                          <v-select
                            v-model="leadstage"
                            label="name"
                            placeholder="None"
                            :options="$store.state.master.leadStages"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Lead Category</label>
                          <v-select
                            v-model="category"
                            label="name"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None"
                            :searchable="false"
                            :options="$store.state.master.leadCategory"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button variant="primary" class="ml-2" @click="searchData">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <b-card-code>
                <report-table :data="data" :columns="fields">
                  <template #printHeader>
                    <div style="display: flex; justify-content: center; width: 100%">
                      <h3 style="margin: 0px">Followup Report</h3>
                    </div>
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      <h4 style="margin: 0px" v-if="lead">
                        Lead Name : {{ lead.fullname }}
                      </h4>
                      <h4 style="margin: 0px" v-if="assignto">
                        Assignto Name : {{ assignto.fullname }}
                      </h4>
                      <h5 style="margin: 0px" v-if="result && result.length == 2">
                        {{ result[0] }} to {{ result[1] }}
                      </h5>
                    </div>
                  </template>
                </report-table>
              </b-card-code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from "../../../components/ReportTable.vue";
import axios from "@/components/axios";
import Table from "@/components/Table.vue";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import moment from "moment";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BAvatar,
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";
import LeadCategoryVue from "@/views/master/crm/leadcategory/LeadCategory.vue";
export default {
  components: {
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    BAvatar,
    GoodTableColumnSearch,
  },
  data() {
    return {
      category: "",
      startdate: "",
      enddate: "",
      result: "",
      assignto: "",
      assigntooption: [],
      leadstage: "",
      leadstageoption: [],
      data: "",
      datetime: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        { field: "name", label: "Name" },
        { field: "mobile", label: "Mobile No" },
        { field: "address", label: "Address" },
        { field: "email", label: "Email" },
        { field: "city", label: "City" },
        { field: "date", label: "Date" },
        { field: "leadage", label: "Lead Age" },
        { field: "leadcategory", label: "Lead Category" },
        { field: "priority", label: "Priority" },
        { field: "followuptype", label: "Follow up Type" },
        { field: "nextfollowupafterdays", label: "Follow up After Days" },
        { field: "leadstage", label: "Lead Stage" },
        { field: "remarks", label: "Remarks" },
      ],
      branchOptions: [
        { name: "Channel Partner", id: "channelpartner" },
        { name: "Franchise", id: "franchise" },
        { name: "Employee", id: "employee" },
        { name: "All", id: "all" },
      ],
      branchname: "",
      assignto: "",
      User: "",
      lead: "",
    };
  },
  mounted() {
    this.$store.dispatch("master/getLeadCategory");
    this.$store.dispatch("master/getLeadStage");
    this.$store.dispatch("master/getUserLead");
    this.User = this.$store.state.app.user_data.role;
    if (this.User !== "admin") {
      this.getEmployee({ id: this.User });
    }
  },
  methods: {
    async getEmployee(e) {
      this.employee = [];
      if (e && e.id == "channelpartner") {
        this.$store.dispatch("master/getChannelpartner");
      } else if (e && e.id == "franchise") {
        this.$store.dispatch("master/getFranchise");
      } else if (e && e.id == "employee") {
        this.$store.dispatch("master/getEmployee");
      }
    },
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result[0];
      this.enddate = this.result[1];
    },
    async searchData() {
      const data = {
        startdate: this.startdate,
        enddate: this.enddate,
        lead: this.lead ? this.lead.id : "",
        assignto: this.assignto ? this.assignto.id : "",
        leadstage: this.leadstage ? this.leadstage.name : "",
        category: this.category ? this.category.id : "",
      };
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/followupreport`,
      }).then((response) => {
        this.data = response.data.data;
        this.data = this.data.map((item) => {
          item.name = item.lead?.name;
          item.mobile = item.lead?.mobile;
          item.email = item.lead?.email;
          item.city = item.lead?.city;
          item.leadstage = item.status?.name;
          item.date =
            item.datetime && item.datetime != "0000-00-00"
              ? moment(item.datetime).format("DD/MM/YYYY")
              : "-";
          item.address = item.lead?.address;
          item.leadcategory = item.lead?.leadcategory[0]?.name;
          item.leadage = moment().diff(item.datetime, "days");
          return item;
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
